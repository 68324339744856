<template>
  <div class="coreTechnologies" ref="pageBox">
    <Header></Header>
    <div class="toTop" v-show="topBtnShow" @click="scrollToTop"></div>
    <div class="item-1" ref="gif1">
      <div class="img-1"></div>
      <div class="sub-title">加速记忆的发动机 优化记忆的管理人</div>
      <div class="content-text">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;记忆引擎（MemoryEngine）是根据人脑在学习过程中的注意、记忆、理解、情绪等方面的认知规律，利用信息技术记录人脑与电脑互动过程中的各项数据，对人脑记忆形成过程进行精准计算和仿生模拟，实时绘制每位学习者针对每项学习内容的记忆图谱，并根据大脑的遗忘规律，精确计算出每项学习内容的下一次最佳复习时间。<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;记引擎用忆智能化实现个性化，达到“人脑学习、引擎管理”。
      </div>
      <div class="gif-1-jpg" v-if="!gifShow_1"></div>
      <div class="gif-1" v-else></div>
    </div>
    <div class="item-2" ref="animate1">
      <div class="title">记忆引擎的发明人</div>
      <div class="sub-title">记忆引擎之父 - 杨宁远博士</div>
      <div class="img-2">
        <div class="name-box">
          <div class="box-item animate-6" :class="animateStart?'animate__animated animate__backInLeft' :'displayNone'">
            <div class="icon"></div><span class="box-item-text">美国斯坦福大学天才少年培训中心 研究员</span>
          </div>
          <div class="box-item animate-5" :class="animateStart?'animate__animated animate__backInLeft' :'displayNone'">
            <div class="icon"></div><span class="box-item-text">美国斯坦福大学语言信息研究中心 研究员</span>
          </div>
          <div class="box-item animate-4" :class="animateStart?'animate__animated animate__backInLeft' :'displayNone'">
            <div class="icon"></div><span class="box-item-text">美国宾州大学认知科学研究所 博士后</span>
          </div>
          <div class="box-item animate-3" :class="animateStart?'animate__animated animate__backInLeft' :'displayNone'">
            <div class="icon"></div><span class="box-item-text">美国纽约大学(NYU)心理学 博士</span>
          </div>
          <div class="box-item animate-2" :class="animateStart?'animate__animated animate__backInLeft' :'displayNone'">
            <div class="icon"></div><span class="box-item-text">北京大学核物理 学士</span>
          </div>
          <div class="name animate-1" :class="animateStart?'animate__animated animate__backInLeft' :'displayNone'">杨宁远 <span class="name-title">博士</span></div>
        </div>
        <div class="text-2">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;记忆引擎的发明是杨博士在脑科学、认知心理学、信息技术三大科研领域交叉的重大科研成果。在记忆引擎的驱动下学习，大脑记忆功能参数得到持续提高，对于促进大脑的发育和保持大脑的活力具有十分重要的意义。
        </div>
      </div>

    </div>
    <div class="item-3">
      <div class="title">记忆引擎的诞生</div>
      <div class="sub-title">从北京大学到斯坦福大学</div>
      <div class="swiper_content">
        <div class="swiper-2">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in imgList_1" :key="item.url">
              <img :src=" require('../assets/img/'+item.url)" alt="">
              <div class="swiper-2-text">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.text}}
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination swiper-2-pagination"></div>
          <div class="swiper-button-prev swiper-2-button-prev"></div>
          <div class="swiper-button-next swiper-2-button-next"></div>
        </div>
      </div>

    </div>
    <div class="item-3">
      <div class="title">记忆引擎的早期应用</div>
      <div class="sub-title">从美国硅谷进入中国云南</div>
      <div class="swiper_content">
        <div class="swiper-2 swiper-2-2">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide-2" v-for="item in imgList_2" :key="item.url">
              <img :src=" require('../assets/img/'+item.url)" alt="">
              <div class="swiper-2-text">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.text}}
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination swiper-2-pagination"></div>
          <div class="swiper-button-prev swiper-2-button-prev"></div>
          <div class="swiper-button-next swiper-2-button-next"></div>
        </div>
      </div>

    </div>

    <div class="item-4" ref="gif2">
      <div class="title">记忆引擎的运行模式</div>
      <div class="sub-title">数据采集 记忆优化 数据存储</div>
      <div class="content-text">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;记忆引擎可以实时监控学习者和电脑互动过程中的各项数据，并将数据传送到记忆优化器中进行计算和分析，从而准确判断出学习内容在人脑中的遗忘点，并在遗忘点到来之前精确、及时地向人脑发出遗忘预警，使人脑在连续、反复的记忆之下，最终对信息形成永久记忆。
      </div>
      <div class="gif-2-jpg" v-if="!gifShow_2"></div>
      <div class="gif-2" v-else></div>
    </div>

    <div class="item-5" ref="gif3">
      <div class="title">记忆引擎的基本原理</div>
      <div class="sub-title">精算下一次最佳复习时间点</div>
      <div class="content-text content-text-1">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;人的记忆过程分为感觉记忆、短时记忆和长时记忆三个阶段。<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;来自环境中信息短暂保持在感觉记忆中，如果没有受到注意便会消退。受到注意的信息会进入短时记忆，在短时记忆中信息经过反复复述才能进入相对持久的长时记忆。
      </div>
      <div class="gif-3-content">
        <div class="gif-3-jpg" v-if="!gifShow_3"></div>
        <div class="gif-3" v-else></div>
      </div>
      <div class="content-text">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;有两个脑区在人类记忆中作用极为突出。人类主要依靠脑的额叶结构产生和提取记忆，颞叶结构永久储存这些记忆。
      </div>
      <div class="img-3"></div>
      <div class="content-text">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;记忆引擎融合了脑科学、认知心理学、信息技术，其详细原理非常复杂，以下阐述其基本原理。<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;记忆引擎的基本原理就是为每个学员的每项学习内容找到下一次最佳复习时间点，在这个时间点复习可以最大程度地获得记忆强度增加值（s）。<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如下图所示：记忆强度增加值（s）=训练函数的变化值（s1）-遗忘函数的变化值（s2）。
      </div>
      <div class="img-4"></div>
      <div class="content-text">
        <div class="text-item">
          <div class="icon-num">1</div><span>记忆强度：</span>是指存储在长时记忆中信息的熟练程度，是多次学习累积的结果。
        </div>
        <div class="text-item">
          <div class="icon-num">2</div><span> 记忆函数：</span>是指每次进行复习时记忆强度的变化规律。
        </div>
        <div class="text-item">
          <div class="icon-num">3</div><span>遗忘函数：</span>是指按照每个学员遗忘曲线形成的记忆强度变化规律。
        </div>
      </div>
    </div>

    <div class="item-6">
      <div class="title">核心技术的全面发展</div>
      <div class="sub-title">获得系列著作权和专利</div>
      <div class="content-text">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;记忆引擎不仅在理论和技术上赢得了巨大突破，在这个技术平台上开发了系列产品，获得了多项计算机软件著作权和国家专利。</div>
      <div class="swiper_content">
        <div class="swiper-3">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in imgList_3" :key="item">
              <img :src=" require('../assets/img/'+item)" alt="">
            </div>
          </div>
        </div>

      </div>
    </div>
    <FooterText :pageStage="3"></FooterText>
    <!-- <div class="bottom">
      <p>版权所有：北京红本科技有限公司</p>
      <p>地址：北京市海淀区上地信息路11号彩虹大厦北楼西段203室</p>
      <p>电话：4008-017-018</p>
    </div> -->
  </div>
</template>

<script>
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
Swiper.use([Autoplay, Navigation, Pagination]);
import Header from "@/components/header";
import FooterText from './footerText.vue'
import "swiper/swiper-bundle.css";
export default {
  name: "index",
  components: {
    Header,
    FooterText,
  },
  data() {
    return {
      topBtnShow:false,
      animateStart: false,
      animate1Height: 0,
      gifShow_1: false,
      gif1: 0,
      gifShow_2: false,
      gif2: 0,
      gifShow_3: false,
      gif3: 0,
      imgList_1: [
        {
          url: "coreTechnologies/swiper-1-1.jpg",
          text: "杨宁远博士在北京大学读核物理本科结束时，由于对大脑信息处理机制发生兴趣, 并特别关注用无损伤的大脑成像技术来揭示与信息处理相关的大脑活动，赴美国纽约大学攻读心理学博士学位。",
        },
        {
          url: "coreTechnologies/swiper-1-2.jpg",
          text: "1998年获得博士学位之后，受邀到宾夕法尼亚大学认知科学研究所，开始从事脑电波和大脑信息处理的研究。",
        },
        {
          url: "coreTechnologies/swiper-1-3.jpg",
          text: "2000年4月，受聘到斯坦福大学语言信息研究中心做研究员，研究如何用脑波来识别大脑的语言信息处理过程。其主要的学术成果体现在博士论文“人类视觉镜象记忆的存活时间的特性与神经机制”。该论文被乔治•斯佩林博士（美国科学院院士）列为他的研究生必读文章。",
        },
        {
          url: "coreTechnologies/swiper-1-4.jpg",
          text: "纽约大学神经磁学实验室的导师Dr. Kaufman描述了杨博士在纽约大学的工作经历：“杨博士对原先镜像记忆的研究方法上做了突破性改进，创造出了精确的对镜像记忆存活时间定量测量的方法，他的研究对记忆缺陷(如老年痴呆—阿兹海默症)的早期发现具有重要的意义。”",
        },
        {
          url: "coreTechnologies/swiper-1-5.jpg",
          text: "纽约大学心理学教授，世界著名的视觉感知和磁神经学专家这样评价杨博士的贡献：“杨博士的研究一直都处在人类信息处理研究的前沿。他的研究跟人类的健康和疾病的问题也紧密相关。由于杨博士研究的重要性，对现实生活中人类的学习，记忆和病理学的研究产生了重要的影响，他的工作对国家的利益有卓越的贡献。我相信随着这些发现不断地被重复和改进，它们将会是在人类认知神经学方面最令人振奋的发现之一。”",
        },
      ],
      imgList_2: [
        {
          url: "coreTechnologies/swiper-2-1.jpg",
          text: "2001年，杨博士从斯坦福大学博士后出站后，在美国硅谷创立记忆引擎为核心技术的高科技公司，研发和推广记忆引擎技术平台上的产品。",
        },
        {
          url: "coreTechnologies/swiper-2-2.jpg",
          text: "2002年，杨博士带着记忆引擎技术回到祖国，从云南开始，开启了记忆引擎在中国的推广和普及之路。",
        },
      ],
      imgList_3: [
        "coreTechnologies/swiper-3-1.jpg",
        "coreTechnologies/swiper-3-2.jpg",
        "coreTechnologies/swiper-3-3.jpg",
        "coreTechnologies/swiper-3-4.jpg",
        "coreTechnologies/swiper-3-5.jpg",
        "coreTechnologies/swiper-3-6.jpg",
        "coreTechnologies/swiper-3-7.jpg",
        "coreTechnologies/swiper-3-8.png",
        "coreTechnologies/swiper-3-9.png",
      ],
    };
  },
  created() {},
  mounted() {
    if (!this._isMobile()) {
      // 跳转至 pc 端路由
      // this.$router.push({
      //   path: '/'
      // })
      let app = document.querySelector('#app');
      app.style.width = "750px";
      app.classList.add("pcStyle");
    }
    this.$nextTick(() => {
      this.swiperInit();
    });
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    swiperInit() {
      let swiper2 = new Swiper(".swiper-2", {
        direction: "horizontal", // 垂直切换选项
        loop: true, // 循环模式选项

        // 如果需要分页器
        pagination: {
          el: ".swiper-2-pagination",
          click: true,
        },
        navigation: {
          nextEl: ".swiper-2-button-next",
          prevEl: ".swiper-2-button-prev",
        },
        // autoplay: {
        //   delay: 2000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true,
        // },

        // 如果需要滚动条
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
      });

      let swiper3 = new Swiper(".swiper-3", {
        direction: "horizontal" /*横向滑动*/,
        observeParents: false, //必须加入
        observer: true, //必须加入
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 30,
        loop: true,
        autoplay: true,
      });

      window.addEventListener("scroll", this.scrollListener, true);

      this.animate1Height = this.$refs.animate1.offsetTop - 200;
      this.gif1 = this.$refs.gif1.offsetTop - 100;
      this.gif2 = this.$refs.gif2.offsetTop - 100;
      this.gif3 = this.$refs.gif3.offsetTop - 100;
    },
    scrollToTop(){
      // this.$refs.pageBox.scrollTo({top: 0, behavior: 'smooth'});
      this.$refs.pageBox.scrollTo({top: 0});
    },
    scrollListener() {
      // let scrollTop =
      //   window.pageYOffset ||
      //   document.documentElement.scrollTop ||
      //   document.body.scrollTop;
      let scrollTop;
      if(this.$refs.pageBox){
        scrollTop = this.$refs.pageBox.scrollTop;
      }
        let height = window.innerHeight;
      if(scrollTop>height){
        this.topBtnShow = true;
      }else {
        this.topBtnShow = false;
      }
      if (scrollTop > this.animate1Height - 200) {
        this.animateStart = true;
      }
      if (scrollTop > this.gif1) {
        this.gifShow_1 = true;
      }
      if (scrollTop > this.gif2) {
        this.gifShow_2 = true;
      }
      if (scrollTop > this.gif3) {
        this.gifShow_3 = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
*{
  font-family: HarmonyOS Sans SC;
}
.displayNone {
  display: none !important;
}
.animate-1 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  --animate-delay: 0.5s;
}
.animate-2 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  --animate-delay: 1s;
}
.animate-3 {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
  --animate-delay: 1.5s;
}
.animate-4 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
  --animate-delay: 2s;
}
.animate-5 {
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  animation-delay: 2.5s;
  --animate-delay: 2.5s;
}
.animate-6 {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-delay: 3s;
  --animate-delay: 3s;
}
.toTop {
  position: fixed;
  right:20px;
  bottom:150px;
  z-index: 10;
  width:50px;
  height:50px;
  opacity: 0.7;
  background: url("../assets/img/project/top.png") round;
}
.coreTechnologies {
  position: relative;
  height:100vh;
  overflow:auto;
  overflow-x:hidden;
  background: rgba(165, 165, 165, 0.18);
  .item-1,
  .item-2,
  .item-3,
  .item-4,
  .item-5,
  .item-6 {
    background: #fff;
    margin-bottom: 10px;
  }
  .item-1 {
    padding: 56px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .img-1 {
      background: url("../assets/img/coreTechnologies/img-1.png") round;
      width: 303px;
      height: 28px;
      margin-bottom: 9px;
    }
    .sub-title {
      font-size: 16px;
      color: #333;
      margin-bottom: 30px;
    }
    .content-text {
      font-size: 14px;
      color: #999;
      line-height: 25px;
      padding: 0 28px;
      text-align: left;
      // margin-bottom: 38px;
    }
    .gif-1 {
      background: url("../assets/img/coreTechnologies/gif-1.gif") round;
      width: 375px;
      height: 236px;
    }
    .gif-1-jpg {
      background: url("../assets/img/coreTechnologies/gif-1.jpg") round;
      width: 375px;
      height: 236px;

    }
  }

  .item-2 {
    padding: 54px 0 46px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 25px;
      color: #df0522;
      margin-bottom: 11px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 16px;
      color: #333;
      margin-bottom: 24px;
    }
    .img-2 {
      position: relative;
      background: url("../assets/img/coreTechnologies/img-2.png") round;
      width: 375px;
      height: 498px;
      .name-box {
        position: absolute;
        top: 35px;
        left: 41px;
        width: 120px;
        height: 300px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        .box-item {
          margin-left: 10px;
          width: 13px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .icon {
            width: 5.5px;
            height: 5.5px;
            border-radius: 50%;
            background: rgb(223, 5, 34);
          }
          .box-item-text {
            margin-top: 5px;
            color: rgb(51, 51, 51);
            font-size: 12px;
            line-height: 12px;
            writing-mode: tb-rl;
            text-align: center;
          }
        }

        .name {
          margin-left: 13px;
          width: 16px;
          font-size: 16px;
          color: #333333;
          // writing-mode:tb-rl;
          // text-align:center;
          font-weight: bold;

          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          line-height: 17px;
          .name-title {
            margin-top: 5.5px;
            font-size: 10.48px;
            line-height: 11px;
            color: #333333;
          }
        }
      }
      .text-2 {
        position: absolute;
        bottom: 17px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        width: 318px;
        font-size: 14px;
        text-align: left;
        color: #ffffff;
        line-height: 22px;
      }
    }
  }

  .item-3 {
    padding: 54px 0 46px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 25px;
      color: #df0522;
      margin-bottom: 11px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 16px;
      color: #333;
      margin-bottom: 24px;
    }
    .swiper_content {
      position: relative;
      overflow: hidden;
    }
    .swiper-2 {
      position: relative;
      width: 375px;
      height: 580px;
      --swiper-theme-color: #999999;
      --swiper-pagination-color: #df0522;
      .swiper-slide {
        width: 375px;
        height: 580px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 375px;
          height: 282px;
          margin-bottom: 32px;
        }
        .swiper-2-text {
          font-weight: 400;
          width: 310px;
          // height: 113.5px;
          font-size: 14px;
          line-height: 25px;
          color: #999;
          text-align: left;
        }
      }
      .swiper-2-pagination {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
      .swiper-2-button-next {
        &:after {
          content: "";
        }
        width: 33px;
        height: 7px;
        background: url("../assets/img/coreTechnologies/swiper-next.png") round;
        position: absolute;
        top: auto;
        bottom: 20px;
        right: 28px;
      }

      .swiper-2-button-prev {
        &:after {
          content: "";
        }
        width: 33px;
        height: 7px;
        background: url("../assets/img/coreTechnologies/swiper-prev.png") round;
        position: absolute;
        top: auto;
        bottom: 20px;
        left: 28px;
      }
    }
    .swiper-2-2 {
      height: 450px;
      .swiper-slide-2 {
        height: 450px;
      }

    }
  }

  .item-4 {
    padding: 56px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 25px;
      color: #df0522;
      margin-bottom: 11px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 16px;
      color: #333;
      margin-bottom: 30px;
    }
    .content-text {
      font-size: 14px;
      color: #999;
      line-height: 25px;
      padding: 0 28px;
      text-align: left;
      // margin-bottom: 20px;
    }
    .gif-2 {
      background: url("../assets/img/coreTechnologies/gif-2.gif") round;
      width: 375px;
      height: 343px;
    }
    .gif-2-jpg {
      background: url("../assets/img/coreTechnologies/gif-2.jpg") round;
      width: 375px;
      height: 343px;
    }
  }

  .item-5 {
    padding: 56px 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 25px;
      color: #df0522;
      margin-bottom: 11px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 16px;
      color: #333;
      margin-bottom: 30px;
    }
    .content-text {
      font-size: 14px;
      color: #999;
      line-height: 25px;
      padding: 0 28px;
      text-align: left;
      margin-bottom: 15px;
      .text-item {
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
        .icon-num {
          display: inline-block;
          width: 17px;
          height: 17px;
          line-height: 17px;
          text-align: center;
          font-size: 15px;
          font-weight: 600;
          color: #fff;
          background: #999;
          border-radius: 50%;
          margin-right: 6px;
        }
        span {
          font-weight: 600;
        }
      }
    }
    
    .content-text-1 {
      margin-bottom: 8px;

    }
    .gif-3-content {
      position: relative;
      width: 375px;
      height: 146px;
      overflow: hidden;
    }
    .gif-3 {
      position: absolute;
      top:50%;
      left:0;
      transform: translate3d(0,-50%,0);
      background-image: url("../assets/img/coreTechnologies/gif-3.gif");
      background-size: 100% 100%;
      width: 375px;
      height: 184px;
    }
    .gif-3-jpg {
      background: url("../assets/img/coreTechnologies/gif-3.jpg") round;
      width: 375px;
      height: 184px;
    }
    .img-3 {
      width: 375px;
      height: 353px;
      background: url("../assets/img/coreTechnologies/img-3.png") round;
      margin-bottom: 13px;
    }
    .img-4 {
      width: 375px;
      height: 193px;
      background: url("../assets/img/coreTechnologies/img-4.png") round;
      margin-bottom: 5px;
    }
  }

  .item-6 {
    padding: 56px 0 76px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 2.5px 5px 1.5px #a5a5a52e;
    .title {
      font-size: 25px;
      color: #df0522;
      margin-bottom: 11px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 16px;
      color: #333;
      margin-bottom: 30px;
    }
    .content-text {
      font-size: 14px;
      color: #999;
      line-height: 25px;
      padding: 0 28px;
      text-align: left;
      margin-bottom: 20px;
    }
    .swiper_content {
      position: relative;
      overflow: hidden;
    }
    .swiper-3 {
      position: relative;
      width: 375px;
      height: 318px;
      --swiper-theme-color: #999999;
      --swiper-pagination-color: #df0522;
      .swiper-slide {
        width: 228px;
        height: 318px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 228px;
          height: 318px;
        }
      }
    }
  }

  .bottom {
    padding: 36px 0;
    p {
      color: #999999;
      font-weight: 400;
      font-size: 11.5px;
      text-align: center;
      line-height: 20.09px;
    }
  }
}
</style>
<style lang="scss">
.pcStyle{
  // width:750px;
  position: relative;
  margin:0 auto;
}
.van-toast  {
  .van-toast__text {
    color: #fff;

  }
}
</style>